'use client';

import { FieldAttributes, FormikErrors } from 'formik';

import FormGroupError from './formGroupError';
import FormGroupLabel from './formGroupLabel';
import { LabelValueItem } from '@/models';
import SelectBox from './selectBox';

type FormGroupSelectProps<T> = FieldAttributes<{
  label?: string;
  errors: { [key: string]: string };
  options: LabelValueItem<string | number | null>[];
  touched: { [key: string]: boolean };
  values: any;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<T>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<T>>;
}>;

export default function FormGroupSelect<T>(props: FormGroupSelectProps<T>) {
  const {
    label,
    errors,
    name,
    multiple,
    options,
    placeholder,
    required,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
  } = props;

  const hasErrors = !!errors[name] && touched[name];

  return (
    <div className="flex flex-col gap-2 mb-2">
      {!!label && (
        <div>
          <FormGroupLabel
            hasErrors={hasErrors}
            label={label}
            name={name}
            required={required}
          />
        </div>
      )}
      <div>
        <SelectBox
          hasErrors={hasErrors}
          isForm={true}
          items={{ data: options }}
          multiple={!!multiple}
          placeholder={placeholder || label || ''}
          selected={multiple ? values[name] : [values[name]]}
          onSelect={items => {
            setFieldTouched(name, true);
            setFieldValue(name, items);
          }}
        />
        {hasErrors && <FormGroupError error={errors[name]} />}
      </div>
    </div>
  );
}

'use client';

import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import ImageWithFallback from '../image/imageWithFallback';
// import portalHeroImage from '../../public/images/portal-hero.webp';

type HeroProps = {
  children: ReactNode;
  collapsed?: boolean;
  heroAlt: string;
  heroSrc?: string;
  heroBg?: string;
};

export default function Hero(props: HeroProps) {
  const {
    children,
    collapsed = false,
    heroAlt,
    heroBg = 'bg-darkblue/80',
    heroSrc = '/images/portal-hero.webp',
  } = props;

  const [error, setError] = useState<SyntheticEvent<
    HTMLImageElement,
    Event
  > | null>(null);

  useEffect(() => {
    setError(null);
  }, [heroSrc]);

  const className = collapsed ? 'h-[250px]' : 'h-[350px] md:h-[600px]';

  return (
    <div className={`bg-darkblue ${className}`}>
      <div className="relative isolate overflow-hidden h-full">
        <ImageWithFallback
          alt={heroAlt}
          className="absolute inset-0 -z-10 w-full h-full object-cover object-center"
          src={error ? '/images/portal-hero.webp' : heroSrc}
          onError={setError}
          fill
          loading="eager"
          priority
        />
        <div
          className={`absolute inset-0 -z-10 h-full w-full ${heroBg}`}
          aria-hidden="true"
        ></div>
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
}

'use client';

export enum LocalStorageKeys {
  selectedCategories = 'selectedCategories',
  selectedLocations = 'selectedLocations',
  selectedIMLC = 'selectedIMLC',
  selectedTitles = 'selectedTitles',
}

export function getLocalStorageItem<T>(key: LocalStorageKeys) {
  let item: string | null = null;

  if (typeof window !== 'undefined') {
    item = localStorage.getItem(key);
  }

  return item ? (JSON.parse(item) as T) : null;
}

export function setLocalStorageItem<T>(key: string, value: T) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

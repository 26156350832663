import { Card } from '@/components';

export default function JobSubmissionFormCardLoading() {
  return (
    <Card>
      <div className="flex flex-col gap-5 animate-pulse">
        <div className="flex flex-col gap-2">
          <h2 className="h-[28px] w-[75%] bg-lightgray rounded"></h2>
          <p className="h-[16px] w-[70%] bg-lightgray rounded"></p>
        </div>
      </div>
    </Card>
  );
}

import Config from '@/config';
import Image from 'next/image';
import Link from 'next/link';

export default function Logo() {
  return (
    <Link aria-label="Go home" href={Config.COMPANY_WEBSITE}>
      <span className="sr-only">{Config.COMPANY_NAME}</span>
      <Image
        alt={`${Config.COMPANY_NAME} Logo`}
        height={58}
        src="/images/logo.webp"
        width={220}
      />
    </Link>
  );
}

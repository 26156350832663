import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

type FormGroupErrorProps = {
  error: String;
};

export default function FormGroupError(props: FormGroupErrorProps) {
  const { error } = props;

  return (
    <div className="flex items-center text-error space-x-1 text-xs">
      <ExclamationTriangleIcon className="h-5 w-5" />
      <p>{error}</p>
    </div>
  );
}

import { ReactNode } from 'react';

type InnerContentProps = {
  children: ReactNode;
};

export default function InnerContent(props: InnerContentProps) {
  const { children } = props;

  return <div className="max-w-7xl mx-auto px-4 h-full">{children}</div>;
}

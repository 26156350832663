import { pluralize } from '@/helpers';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Button from '../button/button';

type JobPagerProps = {
  count: number;
  postfix?: string;
  start: number;
  total: number;
  onPage: (page: number) => void;
};

type PagerButtonProps = {
  disabled: boolean;
  Icon: typeof ChevronLeftIcon;
  label: string;
  offset: number;
  position?: string;
  onPage: (pageOffset: number) => void;
};

function PagerButton(props: PagerButtonProps) {
  const { disabled, Icon, label, offset, position = 'left', onPage } = props;

  const baseClassName =
    'md:w-[125px] flex items-center justify-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red';
  const className = disabled
    ? `bg-red/50 ${baseClassName}`
    : `hover:bg-darkred bg-red ${baseClassName}`;

  return (
    <Button
      aria-label={label}
      className={className}
      disabled={disabled}
      onClick={() => onPage(offset)}
    >
      {position === 'left' && <Icon className="w-4 h-4 me-1" />}
      <span className="hidden md:block">{label}</span>
      {position === 'right' && <Icon className="w-4 h-4 ms-1" />}
    </Button>
  );
}

export default function Pager(props: JobPagerProps) {
  const { count, postfix, start, total, onPage } = props;

  const pageNumber = start / count;
  const startAt = count * pageNumber + 1;
  const end = startAt + count;
  const endAt = end > total ? total : end - 1;
  const previousDisabled = startAt === 1;
  const nextDisabled = endAt === total;

  return count > 0 ? (
    <div className="flex items-center justify-between space-x-5">
      <div className="flex">
        <PagerButton
          disabled={previousDisabled}
          Icon={ChevronLeftIcon}
          label="Previous"
          offset={-1}
          onPage={onPage}
        />
      </div>
      {postfix && (
        <div className="text-sm text-gray text-center">
          {startAt.toLocaleString()} - {endAt.toLocaleString()} of{' '}
          {total.toLocaleString()} {postfix}
          {pluralize(total)}
        </div>
      )}
      <div className="flex">
        <PagerButton
          disabled={nextDisabled}
          Icon={ChevronRightIcon}
          label="Next"
          offset={1}
          position="right"
          onPage={onPage}
        />
      </div>
    </div>
  ) : null;
}

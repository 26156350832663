'use client';

import Config from '@/config';
import { Form, FormikProps, withFormik } from 'formik';

import Button from '../button/button';
import FormGroup from '../form/formGroup';
import jobFormValidationSchema from './jobSubmissionFormValidationSchema';
import { JobSubmission } from '@/models';

type JobSubmissionFormProps = {
  jobOrderId: number;
  onSubmit: (values: JobSubmission) => Promise<void>;
};

type OtherJobSubmissionFormProps = {};

function InnerJobSubmissionForm(
  props: OtherJobSubmissionFormProps & FormikProps<JobSubmission>,
) {
  const { errors, isSubmitting, touched } = props;

  return (
    <Form className="flex flex-col gap-2">
      <FormGroup
        errors={errors}
        label="Email"
        name="email"
        required
        touched={touched}
        type="email"
      />
      <FormGroup
        label={`By selecting this box, you acknowledge and agree to receive communications from ${Config.COMPANY_NAME} via phone, SMS, and email.`}
        name="accept"
        errors={errors}
        touched={touched}
        type="checkbox"
      />
      <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
        Apply Now
      </Button>
    </Form>
  );
}

const JobSubmissionForm = withFormik<JobSubmissionFormProps, JobSubmission>({
  mapPropsToValues: ({ jobOrderId }) => ({
    accept: false,
    email: '',
    jobOrderId,
  }),
  validationSchema: jobFormValidationSchema,
  handleSubmit: async (values, { setSubmitting, props: { onSubmit } }) => {
    await onSubmit(values);
    setSubmitting(false);
  },
})(InnerJobSubmissionForm);

export default JobSubmissionForm;

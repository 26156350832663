type FormGroupLabelProps = {
  hasErrors?: boolean;
  label: string;
  name: string;
  required?: boolean;
};

export default function FormGroupLabel(props: FormGroupLabelProps) {
  const { hasErrors, label, name, required } = props;

  return (
    <label
      htmlFor={name}
      className={`text-sm font-bold ${
        hasErrors ? 'text-error' : 'text-lightgreen'
      }`}
    >
      {label}
      {required ? '*' : ''}
    </label>
  );
}

import { Card, Header } from '@/components';
import { JobSubmission } from '@/models';

import JobSubmissionForm from './jobSubmissionForm';

type JobSubmissionFormCardProps = {
  employmentType: string;
  jobOrderId: number;
  onSubmit: (values: JobSubmission) => Promise<void>;
};

export default function JobSubmissionFormCard(
  props: JobSubmissionFormCardProps,
) {
  const { employmentType, jobOrderId, onSubmit } = props;

  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <Header as="h6" override="h4" variant="primary">
            Interested in this {employmentType} job?
          </Header>
          <p className="text-sm text-darkblue font-light">
            Start your application by entering your email now.
          </p>
        </div>
        <JobSubmissionForm jobOrderId={jobOrderId} onSubmit={onSubmit} />
      </div>
    </Card>
  );
}

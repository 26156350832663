'use client';

import Config from '@/config';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import Button from '../button/button';
import Card from '../card/card';
import Header from '../header/header';
import { useContext } from 'react';
import SettingsContext from '@/contexts/settingsContext';

type ConciergeThankYouCardProps = {
  onResetFilter: () => void;
};

export default function ConciergeThankYouCard(
  props: ConciergeThankYouCardProps,
) {
  const { onResetFilter } = props;

  const settings = useContext(SettingsContext);
  const contactEmailAddress = settings?.contact_email_address || '';

  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col items-center gap-6 text-center">
          <div className="lg:px-16">
            <Header as="h6" override="h4" variant="primary">
              Thank you for registering with
              <br />
              {Config.COMPANY_NAME}!
            </Header>
          </div>
          <CheckCircleIcon className="w-24 h-24 text-lightgreen" />
          <p className="lg:px-8 text-sm text-darkblue font-light text-balance">
            Please check your inbox for an email from{' '}
            <a
              className="hover:underline"
              href={`mailto:${contactEmailAddress}`}
            >
              {contactEmailAddress}
            </a>{' '}
            for next steps in your concierge job search.
          </p>
          <Button onClick={onResetFilter}>Reset filter</Button>
        </div>
      </div>
    </Card>
  );
}

import { MenuItemNode } from '@/lib/queries/menuItems';
import { Dialog } from '@headlessui/react';
import { Dispatch, SetStateAction, useState } from 'react';
import Content from './content';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import Userbar from './userbar';
import Logo from './logo';

type MobileMenuProps = {
  items: {
    node: MenuItemNode;
  }[];
  mobileMenuOpen: boolean;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
};

type MobileMenuItemProps = {
  activeItem: { node: MenuItemNode } | null;
  item: {
    node: MenuItemNode;
  };
  setActiveItem: Dispatch<
    SetStateAction<{
      node: MenuItemNode;
    } | null>
  >;
};

type MobileMenuChildItemProps = {
  item: {
    node: MenuItemNode;
  };
};

function MobileMenuChildItem(props: MobileMenuChildItemProps) {
  const { item } = props;

  const [isOpen, setIsOpen] = useState(false);

  const id = item.node.id;
  const label = item.node.label;
  const target = item.node.url;
  const url = item.node.url;
  const childItems = item.node.childItems.edges;
  const hasChildItems = childItems.length > 0;

  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    <div className="flex flex-col">
      <a
        key={id}
        aria-label={label}
        href={hasChildItems ? undefined : url}
        target={hasChildItems ? undefined : target}
        className="flex items-center justify-between text-darkblue py-2 px-6 cursor-pointer"
        onClick={() => setIsOpen(value => (hasChildItems ? !value : false))}
      >
        {label}
        {hasChildItems && <Icon className="w-5 h-5" />}
      </a>
      {isOpen && (
        <div className="flex flex-col bg-white">
          {childItems.map(childItem => (
            <a
              aria-label={childItem.node.label}
              href={childItem.node.url}
              target={childItem.node.target}
              key={childItem.node.id}
              className="py-2 px-6 text-md text-darkblue cursor-pointer"
            >
              {childItem.node.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

function MobileMenuItem(props: MobileMenuItemProps) {
  const { activeItem, item, setActiveItem } = props;

  const id = item.node.id;
  const label = item.node.label;
  const target = item.node.url;
  const url = item.node.url;
  const childItems = item.node.childItems.edges;
  const hasChildItems = childItems.length > 0;
  const isActive = hasChildItems && activeItem?.node.id === id;

  const Icon = isActive ? ChevronUpIcon : ChevronDownIcon;
  const baseClassName =
    'flex items-center justify-between uppercase cursor-pointer py-4 px-6 text-darkblue';
  const className = `${baseClassName} ${
    isActive ? 'bg-lightgray border-b-4 border-b-lightgreen' : ''
  }`;

  return (
    <div>
      <a
        aria-label={label}
        href={hasChildItems ? undefined : url}
        target={hasChildItems ? undefined : target}
        className={className}
        onClick={() =>
          setActiveItem(value =>
            value && value.node.id === item.node.id ? null : item,
          )
        }
      >
        {label}
        {hasChildItems && <Icon className="w-5 h-5" />}
      </a>
      {isActive && (
        <div className="bg-lightgray">
          {childItems.map(childItem => (
            <MobileMenuChildItem key={childItem.node.id} item={childItem} />
          ))}
        </div>
      )}
    </div>
  );
}

export default function MobileMenu(props: MobileMenuProps) {
  const { items, mobileMenuOpen, setMobileMenuOpen } = props;

  const [activeItem, setActiveItem] = useState<{ node: MenuItemNode } | null>(
    null,
  );

  return (
    <Dialog
      as="div"
      className="lg:hidden"
      open={mobileMenuOpen}
      onClose={setMobileMenuOpen}
    >
      <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white">
        <Content>
          <div className="flex items-center justify-between h-32">
            <Logo />
            <button
              aria-label="Close mobile menu"
              type="button"
              onClick={() => {
                setMobileMenuOpen(false);
                setActiveItem(null);
              }}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="flex flex-col mb-4">
            {items.map(item => (
              <MobileMenuItem
                key={`mobile-menu-item-${item.node.id}`}
                activeItem={activeItem}
                item={item}
                setActiveItem={setActiveItem}
              />
            ))}
          </div>
          <Userbar />
        </Content>
      </Dialog.Panel>
    </Dialog>
  );
}

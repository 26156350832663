import Config from '@/config';

type JobDetailOrderListItemProps = { href: string; label: string };

export default function JobDetailOrderListItem(
  props: JobDetailOrderListItemProps,
) {
  const { href, label } = props;

  return (
    <li className="relative flex gap-x-2">
      <div className="relative flex h-5 w-5 flex-none items-center justify-center bg-white">
        <div className="h-1.5 w-1.5 rounded-full bg-lightgray ring-1 ring-gray/30"></div>
      </div>
      <p className="flex-auto text-sm leading-5 text-darkblue">
        <a
          target="_blank"
          href={`${Config.COMPANY_WEBSITE}${href}`}
          className="text-lightblue/90 hover:text-lightblue underline"
        >
          {label}
        </a>
      </p>
    </li>
  );
}

import * as Yup from 'yup';
import 'yup-phone-lite';

export const PASSWORD_VALIDATORS = [
  {
    errorMessage: 'At least 8 characters',
    regex: /^[A-Za-z\d\S]{8,}$/,
  },
  { errorMessage: '1 lowercase letter', regex: /[a-z]/ },
  { errorMessage: '1 uppercase letter', regex: /[A-Z]/ },
  { errorMessage: '1 number', regex: /[\d]/ },
  { errorMessage: '1 symbol/special character', regex: /[\W]/ },
];

const passwordBaseValidator = Yup.string().required(
  'Create a Password is a required field.',
);
const password = PASSWORD_VALIDATORS.reduce(
  (working, next) => working.matches(next.regex, next.errorMessage),
  passwordBaseValidator,
);

export default Yup.object({
  firstName: Yup.string()
    .max(20, 'First Name must be 20 characters or less.')
    .required('First Name is a required field.'),
  lastName: Yup.string()
    .max(20, 'Last Name must be 20 characters or less.')
    .required('Last Name is a required field.'),
  email: Yup.string()
    .email('Email must be valid.')
    .required('Email is a required field.'),
  phone: Yup.string()
    .phone('US', 'Phone must be valid.')
    .required('Phone is a required field.'),
  city: Yup.string()
    .max(50, 'City must be 50 characters or less.')
    .required('City is a required field.'),
  state: Yup.object({
    value: Yup.string().required('State is a required field.'),
  }).required('State is a required field.'),
  profession: Yup.object({
    value: Yup.string().required('Profession must be a number.'),
  }).required('Profession is a required field.'),
  specialty: Yup.object({
    value: Yup.number().required('Specialty must be a number.'),
  }).required('Specialty is a required field.'),
  npi: Yup.string().matches(/^\d{10}$/, 'NPI must be exactly 10 digits.'),
  // password,
  // confirmPassword: Yup.string()
  //   .min(8, 'Confirm your Password must be at least 8 characters')
  //   .required('Confirm your Password is a required field.')
  //   .oneOf(
  //     [Yup.ref('password')],
  //     'Confirm your Password must match Create a Password.',
  //   ),
  // recommendedBy: Yup.string().max(
  //   20,
  //   'Recommended by must be 20 characters or less.',
  // ),
  resume: Yup.mixed().optional(),
  accept: Yup.boolean().isTrue('Terms and conditions must be accepted.'),
});

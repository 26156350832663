'use client';

import { Card } from '@/components';
import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

export default function JobCardLoading() {
  return (
    <Card padding={false}>
      <div className="flex flex-col lg:flex-row lg:items-center gap-2 animate-pulse">
        <div className="w-full h-32 lg:w-1/4 lg:h-full bg-lightgray relative rounded-t-xl lg:rounded-tr-none lg:rounded-s-xl flex-none">
          <div className="bg-lightgreen/60 absolute top-0 left-0 right-0 bottom-0 z-10 rounded-t-xl lg:rounded-tr-none lg:rounded-s-xl"></div>
        </div>
        <div className="flex flex-col justify-between gap-4 grow p-5 sm:px-9 sm:py-10">
          <h2 className="h-[36px] w-full bg-lightgray rounded"></h2>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-3">
              <BanknotesIcon color="black" width={24} height={24} />
              <p className="h-[20px] w-40 rounded bg-lightgray"></p>
            </div>
            <div className="flex items-center gap-3">
              <UsersIcon color="black" width={24} height={24} />
              <p className="h-[20px] w-36 rounded bg-lightgray"></p>
            </div>
            <div className="flex items-center gap-3">
              <BuildingOfficeIcon color="black" width={24} height={24} />
              <p className="h-[20px] w-20 rounded bg-lightgray"></p>
            </div>
            <div className="flex items-center gap-3">
              <CalendarIcon color="black" width={24} height={24} />
              <p className="h-[20px] w-48 rounded bg-lightgray"></p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

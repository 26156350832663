import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type ButtonProps = { isLoading?: boolean } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export default function Button(props: ButtonProps) {
  const { disabled, isLoading } = props;

  const isDisabled = disabled || isLoading;
  const bgClassName = isDisabled ? 'bg-red/50' : 'hover:bg-darkred';

  const filteredProps = Object.keys(props)
    .filter(key => key !== 'isLoading')
    .reduce((working, next) => {
      working[next] = (props as any)[next];
      return working;
    }, {} as any);

  return (
    <div className="flex flex-col">
      <button
        type="button"
        className={`relative flex items-center justify-center rounded-md bg-red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red ${bgClassName}`}
        {...filteredProps}
      >
        <div className="flex items-center justify-center relative">
          {isLoading && (
            <div className="absolute -left-6">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
          {props.children}
        </div>
      </button>
    </div>
  );
}

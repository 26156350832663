'use client';

import Config from '@/config';
import { Candidate, JobOrder, LabelValueResult } from '@/models';
import { Form, FormikProps, withFormik } from 'formik';

import Button from '../button/button';
import FormGroup from '../form/formGroup';
import FormGroupFileUpload from '../form/formGroupFileUpload';
import FormGroupPhone from '../form/formGroupPhone';
import FormGroupSelect from '../form/formGroupSelect';
import jobFormFullValidationSchema from './candidateFormValidationSchema';

type CandidateFormProps = {
  categories: LabelValueResult<number> | null;
  initialEmail?: string;
  job: JobOrder;
  locations: LabelValueResult<string> | null;
  titles: LabelValueResult<string> | null;
  onSubmit: (values: Candidate) => Promise<void>;
};

type OtherCandidateFormProps = {
  categories: LabelValueResult<number> | null;
  job: JobOrder;
  locations: LabelValueResult<string> | null;
  titles: LabelValueResult<string> | null;
};

function InnerCandidateForm(
  props: OtherCandidateFormProps & FormikProps<Candidate>,
) {
  const {
    categories,
    errors,
    isSubmitting,
    locations,
    titles,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = props;

  return (
    <Form className="flex flex-col gap-2">
      <div className="grid lg:grid-cols-2 lg:gap-5">
        <FormGroup
          errors={errors}
          label="First Name"
          name="firstName"
          required
          touched={touched}
        />
        <FormGroup
          errors={errors}
          label="Last Name"
          name="lastName"
          required
          touched={touched}
        />
      </div>
      <FormGroup
        errors={errors}
        label="Email"
        name="email"
        required
        touched={touched}
        type="email"
      />
      <FormGroupPhone
        errors={errors}
        label="Phone"
        name="phone"
        required
        touched={touched}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <div className="grid lg:grid-cols-2 lg:gap-5">
        <FormGroup
          errors={errors}
          label="City"
          name="city"
          required
          touched={touched}
        />
        <FormGroupSelect
          errors={errors}
          label="State"
          name="state"
          options={locations?.data || []}
          placeholder="Select a state"
          required
          touched={touched}
          values={values}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </div>
      <FormGroupSelect
        errors={errors}
        label="Profession"
        name="profession"
        options={titles?.data || []}
        placeholder="Select a profession"
        required
        touched={touched}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <FormGroupSelect
        errors={errors}
        label="Your Primary Specialty"
        name="specialty"
        options={categories?.data || []}
        placeholder="Select a primary specialty"
        required
        touched={touched}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <FormGroupSelect
        errors={errors}
        label="Licensed States"
        multiple
        name="licensedStates"
        options={locations?.data || []}
        placeholder="Select licensed states"
        touched={touched}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <FormGroup errors={errors} label="NPI" name="npi" touched={touched} />
      {/* <div className="grid lg:grid-cols-2 gap-5">
        <FormGroup
          errors={errors}
          hideErrors={true}
          label="Create a Password"
          name="password"
          required
          touched={touched}
          type="password"
        >
          {touched.password && (
            <CandidatePasswordValidator password={values.password} />
          )}
        </FormGroup>
        <FormGroup
          errors={errors}
          label="Confirm your Password"
          name="confirmPassword"
          required
          touched={touched}
          type="password"
        />
      </div> */}
      <FormGroupFileUpload
        label="Upload Resume"
        name="resume"
        setFieldValue={setFieldValue}
      />
      <FormGroup
        label={`By selecting this box, you acknowledge and agree to receive communications from ${Config.COMPANY_NAME} via phone, SMS, and email.`}
        name="accept"
        errors={errors}
        touched={touched}
        type="checkbox"
      />
      <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
        Register Now
      </Button>
    </Form>
  );
}

const CandidateForm = withFormik<CandidateFormProps, Candidate>({
  mapPropsToValues: props => {
    const { initialEmail } = props;

    return {
      firstName: '',
      lastName: '',
      email: initialEmail || '',
      phone: '',
      city: '',
      state: null,
      profession: null,
      specialty: null,
      licensedStates: [],
      npi: '',
      // password: '',
      // confirmPassword: '',
      accept: false,
    };
  },
  validationSchema: jobFormFullValidationSchema,
  handleSubmit: async (values, { setSubmitting, props: { onSubmit } }) => {
    await onSubmit(values);
    setSubmitting(false);
  },
})(InnerCandidateForm);

export default CandidateForm;

import { LabelValueItem } from '@/models';
import { createContext } from 'react';

type FilterContextValue = {
  selectedCategories: LabelValueItem<number>[];
  selectedIMLC: LabelValueItem<string>[];
  selectedLocations: LabelValueItem<string>[];
  selectedTitles: LabelValueItem<string>[];
};

const FilterContext = createContext<FilterContextValue>({
  selectedCategories: [],
  selectedIMLC: [],
  selectedLocations: [],
  selectedTitles: [],
});

export default FilterContext;

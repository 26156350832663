'use client';

import Config from '@/config';
import { Concierge, LabelValueResult } from '@/models';
import { Form, FormikProps, withFormik } from 'formik';

import Button from '../button/button';
import FormGroup from '../form/formGroup';
import FormGroupFileUpload from '../form/formGroupFileUpload';
import FormGroupPhone from '../form/formGroupPhone';
import FormGroupSelect from '../form/formGroupSelect';
import conciergeFormValidationSchema from './conciergeFormValidationSchema';

type ConciergeFormProps = {
  categories: LabelValueResult<number> | null;
  locations: LabelValueResult<string> | null;
  onSubmit: (values: Concierge) => Promise<void>;
};

type OtherConciergeFormProps = {
  categories: LabelValueResult<number> | null;
  locations: LabelValueResult<string> | null;
};

function InnerConciergeForm(
  props: OtherConciergeFormProps & FormikProps<Concierge>,
) {
  const {
    categories,
    errors,
    isSubmitting,
    locations,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = props;

  return (
    <Form className="flex flex-col gap-2">
      <div className="grid lg:grid-cols-2 lg:gap-5">
        <FormGroup
          errors={errors}
          label="First Name"
          name="firstName"
          required
          touched={touched}
        />
        <FormGroup
          errors={errors}
          label="Last Name"
          name="lastName"
          required
          touched={touched}
        />
      </div>
      <FormGroup
        errors={errors}
        label="Email"
        name="email"
        required
        touched={touched}
        type="email"
      />
      <FormGroupPhone
        errors={errors}
        label="Phone"
        name="phone"
        required
        touched={touched}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <div className="grid lg:grid-cols-2 lg:gap-5">
        <FormGroup
          errors={errors}
          label="City"
          name="city"
          required
          touched={touched}
        />
        <FormGroupSelect
          errors={errors}
          label="State"
          name="state"
          options={locations?.data || []}
          placeholder="Select your state"
          required
          touched={touched}
          values={values}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </div>
      <FormGroupSelect
        errors={errors}
        label="Your Primary Specialty"
        name="specialty"
        options={categories?.data || []}
        placeholder="Select a primary specialty"
        required
        touched={touched}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <FormGroupFileUpload
        label="Upload Resume"
        name="resume"
        setFieldValue={setFieldValue}
      />
      <FormGroup
        label={`By selecting this box, you acknowledge and agree to receive communications from ${Config.COMPANY_NAME} via phone, SMS, and email.`}
        name="accept"
        errors={errors}
        touched={touched}
        type="checkbox"
      />
      <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
        Register Now
      </Button>
    </Form>
  );
}

const ConciergeForm = withFormik<ConciergeFormProps, Concierge>({
  mapPropsToValues: _ => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: null,
    specialty: null,
    accept: false,
  }),
  validationSchema: conciergeFormValidationSchema,
  handleSubmit: async (values, { setSubmitting, props: { onSubmit } }) => {
    await onSubmit(values);
    setSubmitting(false);
  },
})(InnerConciergeForm);

export default ConciergeForm;

export function formatDate(date: number) {
  let formatted = '';

  try {
    const parsed = new Date(date);
    formatted = `${
      parsed.getMonth() + 1
    }/${parsed.getDate()}/${parsed.getFullYear()}`;
  } catch (error: any) {
    // TODO
  }

  return formatted;
}

import { Concierge, LabelValueResult } from '@/models';
import { BriefcaseIcon } from '@heroicons/react/24/outline';

import Card from '../card/card';
import Header from '../header/header';
import ConciergeForm from './conciergeForm';

type ConciergeFormCardProps = {
  categories: LabelValueResult<number> | null | undefined;
  locations: LabelValueResult<string> | null | undefined;
  onSubmitConcierge: (values: Concierge) => Promise<void>;
};

export default function ConciergeFormCard(props: ConciergeFormCardProps) {
  const { categories, locations, onSubmitConcierge } = props;

  return (
    <Card>
      <div className="flex flex-col justify-center items-center p-8 space-y-8">
        <div className="flex items-center justify-center text-lg font-semibold tracking-tight sm:text-xl text-lightgreen">
          <BriefcaseIcon width={100} height={100} />
        </div>
        <Header as="h5" override="h4" variant="primary">
          Let Us Find the Right Fit For You.
        </Header>
        <p className="md:w-2/3 text-sm text-darkblue text-left font-light">
          While the right job for you might not be currently listed on our
          website, a concierge healthcare consultant is standing by to discuss
          your career goals and get to work on finding your perfect job match.
          Use the form below to request a custom job search. A consultant will
          be in touch soon!
        </p>
        <div className="md:w-2/3 flex flex-col gap-5">
          <ConciergeForm
            categories={categories || { data: [] }}
            locations={locations || { data: [] }}
            onSubmit={onSubmitConcierge}
          />
        </div>
        <hr className="border-px border-lightgray w-full" />
        <Header as="h6" override="h4" variant="secondary">
          Less Searching, More Working
        </Header>
      </div>
    </Card>
  );
}

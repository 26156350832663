import * as Yup from 'yup';
import 'yup-phone-lite';

export default Yup.object({
  firstName: Yup.string()
    .max(15, 'First Name must be 15 characters or less.')
    .required('First Name is a required field.'),
  lastName: Yup.string()
    .max(20, 'Last Name must be 20 characters or less.')
    .required('Last Name is a required field.'),
  email: Yup.string()
    .email('Email must be valid.')
    .required('Email is a required field.'),
  phone: Yup.string()
    .phone('US', 'Phone must be valid.')
    .required('Phone is a required field.'),
  city: Yup.string()
    .max(50, 'City must be 50 characters or less.')
    .required('City is a required field.'),
  state: Yup.object({
    value: Yup.string().required('State is a required field.'),
  }).required('State is a required field.'),
  specialty: Yup.object({
    value: Yup.number().required('Specialty must be a number.'),
  }).required('Specialty is a required field.'),
  resume: Yup.mixed().optional(),
  accept: Yup.boolean().isTrue('Terms and conditions must be accepted.'),
});

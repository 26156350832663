'use client';

import Config from '@/config';
import SettingsContext from '@/contexts/settingsContext';
import { formatPhoneNumber } from '@/helpers/phone';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import { useContext } from 'react';

type UserbarItemProps = {
  label: string;
  className: string;
  id: string;
  content: JSX.Element;
  url: string;
};

function UserbarItem(props: UserbarItemProps) {
  const { label, className, id, content, url } = props;

  return (
    <div className="lg:text-xs" key={`userbar-menu-item-${id}`}>
      <a aria-label={label} className={`${className}`} href={url}>
        {content}
      </a>
    </div>
  );
}

export default function Userbar() {
  const className =
    'w-full text-center text-darkblue cursor-pointer inline-block px-4 py-2';

  const settings = useContext(SettingsContext);
  const contactPhoneNumber = settings?.contact_phone_number;
  const contactPhoneNumberFormatted = formatPhoneNumber(contactPhoneNumber);

  return (
    <div className="flex flex-col lg:flex-row lg:fixed lg:top-0 space-y-4 lg:space-y-0">
      <UserbarItem
        label={contactPhoneNumber}
        className={`${className} text-white hover:bg-darkgreen uppercase bg-lightgreen`}
        id="phone"
        content={<>{contactPhoneNumberFormatted}</>}
        url={`tel:${contactPhoneNumber}`}
      />
      <UserbarItem
        label="Apply"
        className={`${className} text-white hover:bg-darkblue uppercase bg-blue`}
        id="apply"
        content={<>Apply</>}
        url={`${Config.COMPANY_WEBSITE}/jobs/apply-now`}
      />
      <UserbarItem
        label="Search"
        className={`${className} text-blue lg:text-white hover:bg-lightgray`}
        id="search"
        content={
          <>
            <MagnifyingGlassIcon className="hidden lg:block text-blue stroke-[3] w-4 h-4" />
            <span className="lg:hidden uppercase">Search</span>
          </>
        }
        url={`${Config.COMPANY_WEBSITE}/search`}
      />
      {/* <UserbarItem
        label="User"
        className={`${className} text-white hover:bg-blue bg-lightblue`}
        id="user"
        content={
          <>
            <UserIcon className="hidden lg:block stroke-[3] w-4 h-4" />
            <span className="lg:hidden uppercase">User</span>
          </>
        }
        url={'/user'}
      /> */}
    </div>
  );
  // return (
  //   <div className="flex flex-col lg:flex-row lg:fixed lg:top-0 space-y-4 lg:space-y-0">
  //     {items.map((item, index) => {
  //       let className =
  //         'w-full text-center text-darkblue cursor-pointer inline-block px-4 py-2';
  //       let content = <>{item.node.label}</>;

  //       if (index === 0) {
  //         className = `${className} text-white hover:bg-darkgreen uppercase bg-lightgreen`;
  //       } else if (index === 1) {
  //         className = `${className} text-white hover:bg-darkblue uppercase bg-blue`;
  //       } else if (index === 2) {
  //         className = `${className} text-blue lg:text-white hover:bg-lightgray`;
  //         content = (
  //           <>
  //             <MagnifyingGlassIcon className="hidden lg:block text-blue stroke-[3] w-4 h-4" />
  //             <span className="lg:hidden uppercase">{content}</span>
  //           </>
  //         );
  //       } else if (index === edges.length - 1) {
  //         className = `${className} text-white hover:bg-blue bg-lightblue`;
  //         content = (
  //           <>
  //             <UserIcon className="hidden lg:block stroke-[3] w-4 h-4" />
  //             <span className="lg:hidden uppercase">{content}</span>
  //           </>
  //         );
  //       } else {
  //         className = `${className} text-white hover:bg-lightgray uppercase`;
  //       }

  //       return (
  //         <div className="lg:text-xs" key={`userbar-menu-item-${item.node.id}`}>
  //           <a
  //             aria-label={item.node.label}
  //             className={`${className}`}
  //             href={item.node.url}
  //           >
  //             {content}
  //           </a>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
}

'use client';

import Config from '@/config';
import GET_MENU_ITEMS_QUERY, {
  MenuItemNode,
  MenuLocation,
} from '@/lib/queries/menuItems';
import { useSuspenseQuery } from '@apollo/client';
import Image from 'next/image';
import Link from 'next/link';
import React, { useContext } from 'react';

import Content from './content';
import SettingsContext from '@/contexts/settingsContext';
import { formatPhoneNumber } from '@/helpers/phone';

type FooterMenuItemProps = {
  item: {
    node: MenuItemNode;
  };
};

function FooterMenuItem(props: FooterMenuItemProps) {
  const { item } = props;

  const id = item.node.id;
  const label = item.node.label;
  const childItems = item.node.childItems.edges;
  const hasChildItems = childItems.length > 0;
  const href = hasChildItems ? undefined : item.node.url;
  const target = hasChildItems ? undefined : item.node.target;
  const className = `py-1 uppercase${hasChildItems ? '' : ' hover:underline'}`;

  return (
    <div
      key={id}
      className={`mt-8 ${
        hasChildItems ? 'flex-1' : 'flex-none'
      } text-white flex flex-col gap-4`}
    >
      {!hasChildItems && (
        <a aria-label={label} href={href} target={target} className={className}>
          {label}
        </a>
      )}
      {hasChildItems && (
        <div aria-label={label} className={className}>
          {label}
        </div>
      )}
      <div className="flex flex-col">
        {childItems.map(childItem => (
          <CategoryMenuItems key={childItem.node.id} item={childItem} />
        ))}
      </div>
    </div>
  );
}

type CategoryMenuItemsProps = {
  item: {
    node: MenuItemNode;
  };
};

function CategoryMenuItems(props: CategoryMenuItemsProps) {
  const { item } = props;

  const url = item.node.url;
  const isCategory =
    url.startsWith('http') &&
    url.startsWith(Config.BASE_URL || location.origin);
  const label = isCategory ? `${item.node.label}:` : item.node.label;
  const childItems = item.node.childItems.edges;
  const href = isCategory ? undefined : item.node.url;
  const target = isCategory ? undefined : item.node.target;
  const className = `py-1 ${
    isCategory ? 'uppercase mt-2 font-bold' : 'hover:underline font-thin'
  }`;

  return (
    <div className="flex flex-col">
      {!isCategory && (
        <a aria-label={label} href={href} target={target} className={className}>
          {label}
        </a>
      )}
      {isCategory && (
        <div aria-label={label} className={className}>
          {label}
        </div>
      )}
      <div className="flex flex-col gap-2">
        {childItems.map(childItem => (
          <a
            key={childItem.node.id}
            aria-label={childItem.node.label}
            href={childItem.node.url}
            target={childItem.node.target}
            className="font-thin py-1 hover:underline"
          >
            {childItem.node.label}
          </a>
        ))}
      </div>
    </div>
  );
}

export default function Footer() {
  const { data } = useSuspenseQuery(GET_MENU_ITEMS_QUERY, {
    variables: {
      location: MenuLocation.FOOTER_NAVIGATION,
    },
  });

  const edges = data?.menuItems?.edges || [];
  const items = [...edges].sort((a, b) => a.node.order - b.node.order);

  const settings = useContext(SettingsContext);
  const address1 = settings?.address_line_1 || '';
  const address2 = settings?.address_line_2 || '';
  const contactEmailAddress = settings?.contact_email_address || '';
  const contactPhoneNumber = settings?.contact_phone_number || '';
  const contactPhoneNumberFormatted = formatPhoneNumber(contactPhoneNumber);

  return (
    <Content>
      <footer className="py-16">
        <div className="flex flex-col lg:flex-row gap-8 md:gap-16">
          <div className="flex-none">
            <div className="flex flex-col gap-8 lg:gap-16">
              <Link className="self-center" href={Config.COMPANY_WEBSITE}>
                <span className="sr-only">{Config.COMPANY_NAME}</span>
                <Image
                  alt={`${Config.COMPANY_NAME} Logo`}
                  height={168}
                  src="/images/logo-white-vertical.webp"
                  width={250}
                />
              </Link>
              <div className="text-white font-thin">
                <div className="py-3">
                  <div>{address1}</div>
                  <div>{address2}</div>
                </div>
                <div className="py-3">
                  Email:{' '}
                  <a
                    className="hover:underline"
                    href={`mailto:${contactEmailAddress}`}
                  >
                    {contactEmailAddress}
                  </a>
                </div>
                <div className="py-3">
                  Phone Number:{' '}
                  <a
                    className="hover:underline"
                    href={`tel:${contactPhoneNumber}`}
                  >
                    {contactPhoneNumberFormatted}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {items.map(item => (
            <FooterMenuItem key={item.node.id} item={item} />
          ))}
        </div>
      </footer>
    </Content>
  );
}

'use client';

import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/navigation';

import Button from '../button/button';
import Card from '../card/card';
import Header from '../header/header';
import { useContext } from 'react';
import SettingsContext from '@/contexts/settingsContext';

type JobSubmissionThankYouCardProps = {
  employmentType: string;
};

export default function JobSubmissionThankYouCard(
  props: JobSubmissionThankYouCardProps,
) {
  const { employmentType } = props;

  const router = useRouter();
  const settings = useContext(SettingsContext);
  const contactEmailAddress = settings?.contact_email_address || '';

  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col items-center gap-6 text-center">
          <div className="lg:px-16">
            <Header as="h6" override="h4" variant="primary">
              Thank you for your interest in this
              <br />
              {employmentType} Job!
            </Header>
          </div>
          <CheckCircleIcon className="w-24 h-24 text-lightgreen" />
          <p className="lg:px-8 text-sm text-darkblue font-light text-balance">
            Please check your inbox for an email from{' '}
            <a
              className="hover:underline"
              href={`mailto:${contactEmailAddress}`}
            >
              {contactEmailAddress}
            </a>{' '}
            for next steps in your job search.
          </p>
          <Button onClick={router.back}>Back to jobs</Button>
        </div>
      </div>
    </Card>
  );
}

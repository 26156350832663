import { gql, TypedDocumentNode } from '@apollo/client';

export type Edges<T> = {
  edges: {
    node: T;
  }[];
};

export type MenuItemNode = {
  id: string;
  label: string;
  order: number;
  target?: string;
  url: string;
  childItems: Edges<MenuItemNode>;
};

export type MenuItemsData = {
  menuItems: Edges<MenuItemNode>;
};

export enum MenuLocation {
  FOOTER_NAVIGATION = 'FOOTER_NAVIGATION',
  PRIMARY_NAVIGATION = 'PRIMARY_NAVIGATION',
}

type MenuItemsVariables = {
  location: MenuLocation;
};

const GET_MENU_ITEMS_QUERY: TypedDocumentNode<
  MenuItemsData,
  MenuItemsVariables
> = gql`
  query GetMenuItems($location: MenuLocationEnum!) {
    menuItems(where: { location: $location, parentDatabaseId: 0 }) {
      edges {
        node {
          label
          target
          url
          id
          order
          childItems {
            edges {
              node {
                label
                target
                url
                id
                order
                childItems {
                  edges {
                    node {
                      label
                      target
                      url
                      id
                      order
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_MENU_ITEMS_QUERY;

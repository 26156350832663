'use client';

import { Card, Header } from '@/components';
import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  IdentificationIcon,
  MapIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

export default function JobDetailCardLoading() {
  return (
    <Card>
      <div className="flex flex-col gap-6 animate-pulse">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4 w-full">
            <div className="flex self-center">
              <BriefcaseIcon
                className="text-lightgreen"
                height={42}
                width={42}
              />
            </div>
            <div className="w-full flex flex-col gap-2">
              <div className="h-[28px] w-[80%] bg-lightgray rounded"></div>
              <div className="h-[28px] w-[20%] bg-lightgray rounded"></div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Header as="h6" override="h4" variant="primary">
            Job Details
          </Header>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-3">
              <BanknotesIcon className="text-gray" width={24} height={24} />
              <p className="h-[16px] w-[50%] bg-lightgray rounded"></p>
            </div>
            <div className="flex items-center gap-3">
              <UsersIcon className="text-gray" width={24} height={24} />
              <p className="h-[16px] w-[25%] bg-lightgray rounded"></p>
            </div>
            <div className="flex items-center gap-3">
              <MapIcon className="text-gray" width={24} height={24} />
              <p className="h-[16px] w-[10%] bg-lightgray rounded"></p>
            </div>
            <div className="flex items-center gap-3">
              <CalendarIcon className="text-gray" width={24} height={24} />
              <p className="h-[16px] w-[30%] bg-lightgray rounded"></p>
            </div>
            <div className="flex items-center gap-3">
              <CalendarIcon className="text-gray" width={24} height={24} />
              <p className="h-[16px] w-[30%] bg-lightgray rounded"></p>
            </div>
            <div className="flex items-center gap-3">
              <BuildingOfficeIcon
                className="text-gray"
                width={24}
                height={24}
              />
              <p className="h-[16px] w-[20%] bg-lightgray rounded"></p>
            </div>
            <div className="flex items-center gap-3">
              <IdentificationIcon
                className="text-gray"
                width={24}
                height={24}
              />
              <p className="h-[16px] w-[10%] bg-lightgray rounded"></p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

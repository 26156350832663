import { createElement, ReactNode } from 'react';

type HeaderProps = {
  as?: string;
  children: ReactNode;
  className?: string;
  override?: string;
  variant?: string;
};

const classNames: { default: string; [key: string]: string } = {
  default: '',
  h1: 'flex items-center justify-start font-freight text-6xl md:text-8xl uppercase text-left',
  h2: 'flex items-center justify-start text-2xl md:text-4xl xl:text-4xl font-light',
  h3: 'flex items-center justify-end text-xl md:text-2xl xl:text-3xl italic font-light',
  h4: 'text-xl sm:text-3xl font-gotham font-bold',
  h5: 'text-xl sm:text-2xl font-gotham font-bold',
  h6: 'text-lg sm:text-xl font-gotham font-bold',
};

const variants: { default: string; [key: string]: string } = {
  default: 'text-white',
  primary: 'text-lightblue',
  secondary: 'text-lightgreen',
};

export default function Header(props: HeaderProps) {
  const { as = 'h1', children, className, override, variant = '' } = props;
  const headerClassName = classNames[as] || classNames.default;
  const selectedVariant = variants[variant] || variants.default;
  const selectedClassName = `${headerClassName} ${
    className || ''
  } ${selectedVariant}`.trim();

  return createElement(
    override || as,
    {
      ...props,
      className: selectedClassName,
    },
    children,
  );
}

'use client';

import { FieldAttributes, FormikErrors } from 'formik';
import PhoneInput from 'react-phone-number-input/input';

import FormGroupError from './formGroupError';
import FormGroupLabel from './formGroupLabel';

type FormGroupPhoneProps<T> = FieldAttributes<{
  label: string;
  errors: { [key: string]: string };
  touched: { [key: string]: boolean };
  values: any;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<T>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<T>>;
}>;

export default function FormGroupPhone<T>(props: FormGroupPhoneProps<T>) {
  const {
    label,
    errors,
    name,
    required,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
  } = props;

  const hasErrors = !!errors[name] && !!touched[name];

  return (
    <div className="flex flex-col gap-2 mb-2">
      <div>
        <FormGroupLabel
          hasErrors={hasErrors}
          label={label}
          name={name}
          required={required}
        />
      </div>
      <div>
        <PhoneInput
          className={`w-full rounded-md border px-5 py-3 mb-2 text-sm ${
            hasErrors
              ? 'border-error placeholder:text-error text-error'
              : 'border-gray/30 text-blue'
          }`}
          country="US"
          international={false}
          placeholder={label}
          value={values[name]}
          onBlur={() => setFieldTouched(name)}
          onChange={value => setFieldValue(name, value)}
        />
        {hasErrors && <FormGroupError error={errors[name]} />}
      </div>
    </div>
  );
}

import { ReactNode } from 'react';

type ContentProps = {
  children: ReactNode;
  className?: string;
};

export default function Content(props: ContentProps) {
  const { children, className } = props;

  return (
    <div
      className={`container mx-auto px-4 sm:px-6 lg:px-16 ${className}`.trim()}
    >
      {children}
    </div>
  );
}

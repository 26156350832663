'use client';

import { LabelValueItem, LabelValueResult } from '@/models';
import { Combobox } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useCallback, useRef, useState } from 'react';

type SelectBoxProps<T> = {
  canClear?: boolean;
  hasErrors?: boolean;
  isForm?: boolean;
  items?: LabelValueResult<T> | null;
  multiple?: any;
  placeholder: string;
  selected?: LabelValueItem<T>[];
  showItems?: number;
  onBlur?: () => void;
  onSelect: (item: LabelValueItem<T>[] | LabelValueItem<T>) => void;
};

export default function SelectBox<T>(props: SelectBoxProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    canClear,
    hasErrors,
    isForm,
    items,
    placeholder,
    multiple,
    selected,
    showItems = 5,
    onBlur,
    onSelect,
  } = props;

  const [query, setQuery] = useState('');
  const [showMore, setShowMore] = useState(false);

  const onChange = useCallback(
    (values: LabelValueItem<T>[] | LabelValueItem<T>) => {
      onSelect(values);
      setQuery('');

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [onSelect],
  );

  const shownItems = showMore
    ? selected
    : selected && selected.length > showItems
    ? selected?.slice(0, showItems)
    : selected;

  const data = items?.data || [];
  const filteredItems = !query
    ? data
    : data.filter(item => {
        return item.label.toLowerCase().includes(query.toLowerCase());
      });

  const value = selected || [];
  const values = value.filter(v => !!v).map(({ value }) => value);

  return (
    <Combobox
      disabled={data.length === 0}
      as="div"
      multiple={multiple}
      value={value}
      onChange={onChange}
    >
      <div className="relative">
        <Combobox.Button
          className={({ open }) =>
            `flex items-center justify-between w-full bg-white py-3 ps-5 pe-2 focus:outline-none mb-2 ${
              isForm
                ? 'border border-gray/30'
                : 'focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-red'
            } ${hasErrors ? 'border-red' : ''} ${
              open ? 'rounded-t-md' : 'rounded-md'
            }`
          }
        >
          <Combobox.Input
            className={`w-full focus:outline-none text-sm bg-white ${
              hasErrors ? 'placeholder:text-error text-error' : 'text-blue'
            }`}
            displayValue={(items: any) =>
              multiple
                ? undefined
                : items && items.length > 0 && items[0] && items[0].label
            }
            placeholder={placeholder}
            ref={inputRef}
            onBlur={() => onBlur && onBlur()}
            onChange={event => setQuery(event.target.value)}
          />
          <ChevronUpDownIcon
            className={`h-5 w-5 stroke-[3] ${
              hasErrors ? 'text-error' : 'text-gray/50'
            }`}
            aria-hidden="true"
          />
        </Combobox.Button>
        {filteredItems && filteredItems.length > 0 && (
          <Combobox.Options
            className={`absolute z-10 max-h-60 w-full overflow-auto bg-white rounded-b-md top-[44px] ${
              hasErrors ? 'border-red' : ''
            } ${isForm ? 'border border-gray/30' : ''}`}
          >
            {canClear && (
              <Combobox.Option
                disabled={value.length === 0}
                key={`clear-selection-${placeholder}`}
                value={null}
                className={({ disabled }) =>
                  `sticky top-0 cursor-pointer select-none py-2 pl-3 pr-9 font-book text-sm bg-white z-20 ${
                    disabled
                      ? 'text-darkgray'
                      : 'hover:bg-lighterror text-darkblue'
                  }`
                }
              >
                {({ disabled }) => (
                  <>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                      <XMarkIcon
                        className={`h-5 w-5 stroke-[3] ${
                          disabled ? 'text-gray/30' : 'text-red'
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                    <span
                      className={`block truncate pl-8 ${
                        disabled ? 'text-gray/30' : 'text-blue'
                      }`}
                    >
                      Clear selection
                    </span>
                  </>
                )}
              </Combobox.Option>
            )}
            {filteredItems.map((item, index) => (
              <Combobox.Option
                key={`${item.value}-${index}`}
                value={item}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-3 pr-9 font-book text-sm text-darkblue z-10 ${
                    active ? 'bg-lightgreen/10' : ''
                  }`
                }
              >
                {({ active, selected }) => (
                  <>
                    {(selected || values.includes(item.value)) && (
                      <span
                        className={`${
                          active ? 'text-white' : 'text-blue'
                        } absolute inset-y-0 left-0 flex items-center pl-4`}
                      >
                        <CheckIcon
                          className="h-5 w-5 stroke-[3] text-success"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                    <span className="block truncate pl-8">{item.label}</span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {!!multiple && shownItems && shownItems.length > 0 && (
        <div className="flex flex-wrap items-center gap-2">
          {shownItems.map(({ label, value }, index) => (
            <div
              className="cursor-pointer hover:shadow flex items-center gap-1 bg-white rounded px-2 py-1 text-xs text-darkblue hover:scale-105 text-lightblue"
              key={`${placeholder}-${label}-${index}`}
              onClick={() =>
                onSelect(selected?.filter(s => s.value !== value) || [])
              }
            >
              <XMarkIcon className="h-4 w-4 stroke-[3] text-red"></XMarkIcon>
              {label}
            </div>
          ))}
          {selected && selected.length > showItems && (
            <div
              className={`cursor-pointer text-xs font-semibold px-2 py-1 ${
                isForm ? 'text-lightblue' : 'text-white'
              }`}
              onClick={() => setShowMore(value => !value)}
            >
              Show {showMore ? 'less' : 'more'}
            </div>
          )}
        </div>
      )}
    </Combobox>
  );
}

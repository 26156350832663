import { createContext } from 'react';

export type SettingsContextValue = {
  address_line_1: string;
  address_line_2: string;
  career_portal_url: string;
  contact_email_address: string;
  contact_phone_number: string;
};

export const defaultValue = {
  address_line_1: '',
  address_line_2: '',
  career_portal_url: '',
  contact_email_address: '',
  contact_phone_number: '',
};

const SettingsContext = createContext<SettingsContextValue>(defaultValue);

export default SettingsContext;

import Config from '@/config';

export const fetchApi = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
) => {
  return fetch(input, {
    ...(init || {}),
    next: { revalidate: Config.CACHE_SECONDS },
  });
};

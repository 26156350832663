import { MenuItemNode } from '@/lib/queries/menuItems';
import {
  Bars3Icon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { Dispatch, SetStateAction, useState } from 'react';

import Logo from './logo';
import Userbar from './userbar';
import Config from '@/config';

type MenuItemProps = {
  activeItem: { node: MenuItemNode } | null;
  item: { node: MenuItemNode };
  setActiveItem: Dispatch<
    SetStateAction<{
      node: MenuItemNode;
    } | null>
  >;
};

type ChildMenuItemProps = MenuItemProps;

type ActiveChildMenuProps = {
  activeItem: { node: MenuItemNode };
};

type DesktopMenuProps = {
  items: { node: MenuItemNode }[];
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
};

function ActiveChildMenu(props: ActiveChildMenuProps) {
  const { activeItem } = props;

  return (
    <div className="flex flex-col bg-white py-8 shadow-lg">
      <div className="pb-2 px-8 text-lightgreen uppercase whitespace-nowrap">
        {activeItem.node.label}
      </div>
      {activeItem.node.childItems.edges.map(item => (
        <div key={item.node.id} className="flex">
          <a
            aria-label={item.node.label}
            href={item.node.url}
            target={item.node.target}
            className="hover:text-lightgreen whitespace-nowrap pb-2 px-8 text-md"
          >
            {item.node.label}
          </a>
        </div>
      ))}
    </div>
  );
}

function ChildMenuItem(props: ChildMenuItemProps) {
  const { activeItem, item, setActiveItem } = props;

  const id = item.node.id;
  const url = item.node.url;
  const isCategory =
    url.startsWith('http') &&
    url.startsWith(Config.BASE_URL || location.origin);
  const label = isCategory ? `${item.node.label}:` : item.node.label;
  const target = item.node.url;
  const childItems = item.node.childItems.edges;
  const hasChildItems = childItems.length > 0;
  const showActiveMenu = hasChildItems && !isCategory;
  const isStaticMenuItem = hasChildItems || isCategory;

  return (
    <div
      onMouseEnter={() => setActiveItem(showActiveMenu ? item : null)}
      className="flex flex-col"
    >
      {isStaticMenuItem && (
        <div
          aria-label={label}
          className={`flex items-center hover:text-lightgreen whitespace-nowrap pb-2 px-8 ${
            isCategory ? 'text-lightgreen uppercase mt-4' : 'cursor-pointer'
          } ${
            activeItem?.node.id === id
              ? `text-lightgreen after:z-20 after:absolute after:-right-[15px] after:text-white after:content-['*'] after:w-[25px] after:rotate-45 after:bg-white`
              : ''
          }`}
        >
          {label}
          {showActiveMenu && <ChevronRightIcon className="ms-1 w-6 h-6" />}
        </div>
      )}
      {!isStaticMenuItem && (
        <a
          aria-label={label}
          href={url}
          target={target}
          className="flex items-center hover:text-lightgreen whitespace-nowrap pb-2 px-8 cursor-pointer"
        >
          {label}
        </a>
      )}
      {hasChildItems &&
        isCategory &&
        childItems.map(childItem => (
          <a
            key={childItem.node.id}
            aria-label={childItem.node.label}
            href={childItem.node.url}
            target={childItem.node.target}
            className="hover:text-lightgreen pb-2 px-8 whitespace-nowrap"
          >
            {childItem.node.label}
          </a>
        ))}
    </div>
  );
}

function MenuItem(props: MenuItemProps) {
  const { activeItem, item, setActiveItem } = props;

  const label = item.node.label;
  const childItems = item.node.childItems.edges;
  const hasChildItems = childItems.length > 0;
  const target = hasChildItems ? undefined : item.node.target;
  const url = hasChildItems ? undefined : item.node.url;

  return (
    <div
      onMouseEnter={() => setActiveItem(null)}
      key={item.node.id}
      className="h-full relative group/menu hover:bg-lightgray transition-all ease-in-out duration-150"
    >
      {!hasChildItems && (
        <a
          aria-label={label}
          href={url}
          target={target}
          className="group-hover/menu:border-b-lightgreen relative z-20 transition-all ease-in-out duration-150 flex items-center gap-2 py-4 px-6 uppercase border-b-4 border-b-white cursor-pointer"
        >
          {label}
        </a>
      )}
      {hasChildItems && (
        <>
          <div
            aria-label={label}
            className="group-hover/menu:border-b-lightgreen relative z-20 transition-all ease-in-out duration-150 flex items-center gap-2 py-4 px-6 uppercase border-b-4 border-b-white cursor-pointer"
          >
            {label}
            {hasChildItems && <ChevronDownIcon className="w-5 h-5" />}
          </div>
          <div className="shadow-lg z-0 group-hover/menu:translate-y-0 transition-all -translate-y-[100%] duration-300 ease-in-out absolute bg-lightgray/95">
            <div className="flex">
              <div className="flex flex-col py-8 relative">
                {childItems.map(childMenuItem => (
                  <ChildMenuItem
                    key={childMenuItem.node.id}
                    activeItem={activeItem}
                    item={childMenuItem}
                    setActiveItem={setActiveItem}
                  />
                ))}
              </div>
              {activeItem && <ActiveChildMenu activeItem={activeItem} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default function DesktopMenu(props: DesktopMenuProps) {
  const { items, setMobileMenuOpen } = props;

  const [activeItem, setActiveItem] = useState<{ node: MenuItemNode } | null>(
    null,
  );

  return (
    <nav className="flex items-center justify-between h-full">
      <div className="relative z-10">
        <Logo />
      </div>
      <div className="flex lg:hidden">
        <button
          aria-label="Open mobile menu"
          type="button"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="hidden lg:flex lg:flex-col lg:justify-between h-full">
        <div className="relative z-20 flex justify-end">
          <Userbar />
        </div>
        <div className="flex bg-white text-darkblue">
          {items.map(item => (
            <MenuItem
              key={item.node.id}
              activeItem={activeItem}
              item={item}
              setActiveItem={setActiveItem}
            />
          ))}
          <div className="absolute z-0 top-0 left-0 right-0 bottom-0 bg-white shadow-lg"></div>
        </div>
      </div>
    </nav>
  );
}

'use client';

import { Candidate, JobOrder, JobSubmission } from '@/models';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';

import CandidateFormCard from '../candidate/candidateFormCard';
import CandidateThankYouCard from '../candidate/candidateThankYouCard';
import JobSubmissionFormCard from '../jobSubmission/jobSubmissionFormCard';
import JobSubmissionThankYouCard from '../jobSubmission/jobSubmissionThankYouCard';
import JobDetailCard from './jobDetailCard';
import InnerContent from '../layout/innerContent';
import Hero from '../layout/hero';
import { ScrollToTop } from '../layout/scrollToTop';
import { fetchApi } from '@/helpers/api';

enum JobApplicationStates {
  Apply,
  ApplyError,
  ApplyThankYou,
  Register,
  RegisterError,
  RegisterThankYou,
}

type JobDetailProps = { job: JobOrder | null };

export default function JobDetail(props: JobDetailProps) {
  const { job } = props;

  const router = useRouter();

  const [jobApplicationState, setJobApplicationState] = useState(
    JobApplicationStates.Apply,
  );
  const [initialEmail, setInitialEmail] = useState('');

  const sendJobSubmission = useCallback(async (values: JobSubmission) => {
    let applicationState = JobApplicationStates.Apply;

    try {
      const response = await fetchApi('/api/jobSubmissions', {
        body: JSON.stringify(values),
        method: 'POST',
      });

      applicationState = response.ok
        ? JobApplicationStates.ApplyThankYou
        : JobApplicationStates.Register;
    } catch (error: any) {
      applicationState = JobApplicationStates.ApplyError;
      console.log(error); // TODO
    }

    return applicationState;
  }, []);

  const scrollToTop = useCallback(() => {
    const yOffset = -180;
    const element = document.querySelector('main');
    const top = element
      ? element.getBoundingClientRect().top + window.scrollY + yOffset
      : 0;

    window.scrollTo({ top, behavior: 'smooth' });
  }, []);

  const onSubmitJob = useCallback(
    async (values: JobSubmission) => {
      const { email } = values;

      const applicationState = await sendJobSubmission(values);

      setInitialEmail(email);
      setJobApplicationState(applicationState);
      scrollToTop();
    },
    [scrollToTop, sendJobSubmission],
  );

  const onSubmitCandidate = useCallback(
    async (values: Candidate) => {
      let applicationState = JobApplicationStates.Register;

      try {
        const body = new FormData();
        const anyValues = values as { [key: string]: any };
        const objectKeys = [
          'state',
          'profession',
          'specialty',
          'licensedStates',
        ];

        Object.keys(anyValues).forEach(key =>
          body.append(
            key,
            objectKeys.includes(key)
              ? JSON.stringify(anyValues[key])
              : anyValues[key],
          ),
        );

        body.append('formName', 'Jobs Portal - Apply Now');

        const response = await fetchApi('/api/candidates', {
          body,
          method: 'POST',
        });

        applicationState = response.ok
          ? JobApplicationStates.RegisterThankYou
          : JobApplicationStates.RegisterError;
      } catch (error: any) {
        applicationState = JobApplicationStates.RegisterError;
        console.log(error); // TODO
      }

      if (applicationState !== JobApplicationStates.RegisterError && job?.id) {
        applicationState = await sendJobSubmission({
          accept: values.accept,
          email: values.email,
          jobOrderId: job.id,
        });
      }

      applicationState =
        applicationState === JobApplicationStates.ApplyError
          ? JobApplicationStates.RegisterError
          : applicationState;

      setJobApplicationState(applicationState);
      scrollToTop();
    },
    [job?.id, scrollToTop, sendJobSubmission],
  );

  return (
    <>
      <ScrollToTop />
      {job?.imageUrl && (
        <Hero
          collapsed
          heroAlt="Search jobs nationwide"
          heroSrc={job?.imageUrl}
        >
          <InnerContent>
            <div className="space-y-5"></div>
          </InnerContent>
        </Hero>
      )}
      <main>
        <InnerContent>
          <div className="relative -top-32">
            <div className="flex flex-col lg:flex-row gap-5">
              {job && (
                <div className="w-full lg:w-1/2">
                  <JobDetailCard job={job} onBack={router.back} />
                </div>
              )}
              <div className="w-full lg:w-1/2">
                {job &&
                  (jobApplicationState === JobApplicationStates.Apply ||
                    jobApplicationState ===
                      JobApplicationStates.ApplyError) && (
                    <JobSubmissionFormCard
                      employmentType={job.employmentType}
                      jobOrderId={job.id}
                      onSubmit={onSubmitJob}
                    />
                  )}
                {job &&
                  jobApplicationState ===
                    JobApplicationStates.ApplyThankYou && (
                    <JobSubmissionThankYouCard
                      employmentType={job.employmentType}
                    />
                  )}
                {job &&
                  (jobApplicationState === JobApplicationStates.Register ||
                    jobApplicationState ===
                      JobApplicationStates.RegisterError) && (
                    <CandidateFormCard
                      job={job}
                      initialEmail={initialEmail}
                      onSubmit={onSubmitCandidate}
                    />
                  )}
                {jobApplicationState ===
                  JobApplicationStates.RegisterThankYou && (
                  <CandidateThankYouCard />
                )}
              </div>
            </div>
          </div>
        </InnerContent>
      </main>
    </>
  );
}

'use client';

import { useEffect } from 'react';

export const ScrollToTop = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
  }, []);

  return null;
};

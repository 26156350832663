'use client';

import GET_MENU_ITEMS_QUERY, { MenuLocation } from '@/lib/queries/menuItems';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { useState } from 'react';

import Content from './content';
import DesktopMenu from './desktopMenu';
import MobileMenu from './mobileMenu';

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { data } = useSuspenseQuery(GET_MENU_ITEMS_QUERY, {
    variables: {
      location: MenuLocation.PRIMARY_NAVIGATION,
    },
  });

  const edges = data?.menuItems?.edges || [];
  const items = [...edges].sort((a, b) => a.node.order - b.node.order);

  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white shadow-lg h-32">
      <Content className="h-full">
        <DesktopMenu items={items} setMobileMenuOpen={setMobileMenuOpen} />
      </Content>
      <MobileMenu
        items={items}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </header>
  );
}

import Config from '@/config';

export function getSearchParams(
  url: string,
  defaultValue = {
    count: Config.BULLHORN_PAGE_SIZE,
    searchParams: new URLSearchParams(),
    start: 0,
  },
) {
  let searchParams = defaultValue;

  try {
    const parsedUrl = new URL(url);
    const parsedSearchParams = parsedUrl.searchParams;
    const count = Config.BULLHORN_PAGE_SIZE;
    const start = Math.max(0, Number(parsedSearchParams.get('start') || 0));

    searchParams = {
      count,
      searchParams: parsedSearchParams,
      start,
    };
  } catch (error: any) {
    // TODO
  }

  return searchParams;
}

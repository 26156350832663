'use client';

import {
  CheckCircleIcon,
  DocumentArrowUpIcon,
} from '@heroicons/react/24/solid';
import { Field, FieldAttributes } from 'formik';
import { useCallback, useRef, useState } from 'react';

import FormGroupLabel from './formGroupLabel';
import { XMarkIcon } from '@heroicons/react/20/solid';

type FormGroupFileUpload = FieldAttributes<{
  accept?: string;
  label: string;
  setFieldValue: any;
}>;

export default function FormGroupFileUpload(props: FormGroupFileUpload) {
  const fileInputRef = useRef<any>(); // TODO: Add type

  const {
    accept = 'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    label,
    name,
    placeholder,
    required,
    type = 'file',
    setFieldValue,
  } = props;

  const [file, setFile] = useState<File | null>(null);
  const [isDropAreaHovered, setIsDropAreaHovered] = useState(false);

  const acceptedFileFormats = (accept || '')
    .split(',')
    .map((format: string) => format.trim());

  const onFileSize = useCallback((size: number, bytes = 1000) => {
    const options = ['B', 'kB', 'MB', 'GB', 'TB'];
    const index = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(bytes));
    const total = (size / Math.pow(bytes, index)).toFixed(2);

    return `${total} ${options[index]}`;
  }, []);

  const onFileDragEnter = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setIsDropAreaHovered(true);
  }, []);

  const onFileDragLeave = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setIsDropAreaHovered(false);
  }, []);

  const onFileDragOver = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onFileDrop = useCallback(
    async (event: any) => {
      event.stopPropagation();
      event.preventDefault();

      const filesUploaded =
        event?.target?.files ||
        (event?.dataTransfer?.files as FileList) ||
        new FileList();
      const fileUpload = filesUploaded.item(0);

      if (fileUpload && acceptedFileFormats.includes(fileUpload.type)) {
        setFile(fileUpload);
        setFieldValue(name, fileUpload);
      }

      setIsDropAreaHovered(false);
    },
    [acceptedFileFormats, name, setFieldValue],
  );

  return (
    <div className="flex flex-col gap-2 mb-2">
      <div>
        <FormGroupLabel label={label} name={name} required={required} />
      </div>
      <Field
        accept={accept}
        innerRef={fileInputRef}
        hidden
        className={`w-full rounded-md border px-5 py-3 mb-2 text-sm`}
        id={name}
        placeholder={placeholder || label}
        name={name}
        type={type}
        value=""
        onChange={onFileDrop}
      />
      {!file && (
        <div
          className={`flex flex-col gap-5 items-center justify-center border-2 p-5 rounded border-gray/30 ${
            isDropAreaHovered ? '' : 'border-dashed'
          }`}
          onDragEnter={onFileDragEnter}
          onDragLeave={onFileDragLeave}
          onDragOver={onFileDragOver}
          onDrop={onFileDrop}
        >
          <DocumentArrowUpIcon
            className="text-gray/30"
            width={42}
            height={42}
          />
          <p className="text-xs font-thin">
            Drag and drop file here or{' '}
            <span
              className="cursor-pointer underline"
              onClick={() => fileInputRef.current?.click()}
            >
              select from your device
            </span>
          </p>
        </div>
      )}
      {file && (
        <div className="flex items-center gap-5 border border-gray/30 p-5 rounded text-sm">
          <CheckCircleIcon className="w-8 h-8 text-lightgreen" />
          <div className="flex flex-col flex-grow gap-1">
            <div className="text-blue leading-[1]">{file.name}</div>
            <div className="text-xs text-gray/90 font-thin">
              {onFileSize(file.size)}
            </div>
          </div>
          <XMarkIcon
            className="transition ease-in-out delay-0 hover:scale-125 w-4 h-4 cursor-pointer stroke-[3] text-red"
            onClick={() => {
              setFile(null);
              setFieldValue(name, null);
            }}
          />
        </div>
      )}
    </div>
  );
}

'use client';

import FilterContext from '@/contexts/filterContext';
import { LabelValueItem, LabelValueResult } from '@/models';
import { Switch } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { useCallback, useContext, useState } from 'react';

import SelectBox from '../form/selectBox';
import Header from '../header/header';

type JobFilterProps = {
  categories?: LabelValueResult<number> | null;
  locations?: LabelValueResult<string> | null;
  titles?: LabelValueResult<string> | null;
  onResetFilter: () => void;
  onSelectCategory: (items: LabelValueItem<number>[]) => void;
  onSelectIMLC: (items: LabelValueItem<string>[]) => void;
  onSelectLocation: (items: LabelValueItem<string>[]) => void;
  onSelectTitle: (items: LabelValueItem<string>[]) => void;
};

const IMLC_YES_VALUE = {
  label: 'Yes',
  value: 'Yes - in hand Yes - eligible',
};

export default function JobFilter(props: JobFilterProps) {
  const {
    categories,
    locations,
    titles,
    onResetFilter,
    onSelectTitle,
    onSelectCategory,
    onSelectIMLC,
    onSelectLocation,
  } = props;

  const {
    selectedCategories,
    selectedIMLC,
    selectedLocations,
    selectedTitles,
  } = useContext(FilterContext);

  const disabled =
    selectedCategories.length === 0 &&
    selectedIMLC.length === 0 &&
    selectedLocations.length === 0 &&
    selectedTitles.length === 0;

  const [showFilter, setShowFilter] = useState(false);

  const enabled = selectedIMLC
    .map(({ value }) => value)
    .includes(IMLC_YES_VALUE.value);

  const onToggleShowFilter = useCallback(() => {
    setShowFilter(value => !value);
  }, []);

  const onSelect = useCallback(
    <T,>(onSelectOption: (items: LabelValueItem<T>[]) => void) => {
      return (items: LabelValueItem<T>[] | LabelValueItem<T>) => {
        onSelectOption(Array.isArray(items) ? items : []);
      };
    },
    [],
  );

  return (
    <div className="sticky top-36 shadow-lg w-full md:flex md:justify-center bg-red p-5 rounded-xl">
      <div className="w-full flex flex-col z-30 gap-5 text-left">
        <Header as="h6" override="h4">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={onToggleShowFilter}
          >
            <div className="flex items-center">
              <FunnelIcon
                className="mr-2 text-lightred"
                width={24}
                height={24}
              />
              Filter jobs
            </div>
            {showFilter && (
              <ChevronDownIcon
                className="lg:hidden text-lightred"
                width={24}
                height={24}
              />
            )}
            {!showFilter && (
              <ChevronUpIcon
                className="lg:hidden  text-lightred"
                width={24}
                height={24}
              />
            )}
          </div>
        </Header>
        <div
          className={`flex flex-col gap-5 ${
            showFilter ? '' : 'hidden'
          } lg:contents`}
        >
          <SelectBox
            canClear={true}
            items={titles}
            multiple
            placeholder="Select Job Title"
            selected={selectedTitles}
            onSelect={onSelect(onSelectTitle)}
          />
          <SelectBox
            canClear={true}
            items={categories}
            multiple
            placeholder="Select Specialties by Profession"
            selected={selectedCategories}
            onSelect={onSelect(onSelectCategory)}
          />
          <SelectBox
            canClear={true}
            items={locations}
            multiple
            placeholder="Select Locations"
            selected={selectedLocations}
            onSelect={onSelect(onSelectLocation)}
          />
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={enabled}
              onChange={value => onSelectIMLC(value ? [IMLC_YES_VALUE] : [])}
              className={`${
                enabled ? 'bg-lightgreen' : 'bg-lightgray'
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lightgreen focus:ring-offset-2`}
            >
              <span
                aria-hidden="true"
                className={`${
                  enabled ? 'translate-x-5' : 'translate-x-0'
                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-white">Accepts IMLC</span>{' '}
            </Switch.Label>
          </Switch.Group>
          <div className="flex h-full w-full">
            <button
              disabled={disabled}
              type="button"
              className={`whitespace-nowrap rounded-md bg-white/10 text-sm font-semibold shadow-sm hover:bg-white/20 w-full h-full py-3 px-5 ${
                disabled ? 'text-white/20' : 'text-white'
              }`}
              onClick={onResetFilter}
            >
              Reset filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

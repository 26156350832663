import { Field, FieldAttributes, FormikErrors, FormikTouched } from 'formik';

import FormGroupError from './formGroupError';
import FormGroupLabel from './formGroupLabel';

type FormGroupProps<T> = FieldAttributes<{
  hideErrors?: boolean;
  label: string;
  errors: { [key: string]: string };
  touched: { [key: string]: boolean };
}>;

export default function FormGroup<T>(props: FormGroupProps<T>) {
  const {
    children,
    errors,
    hideErrors,
    label,
    name,
    placeholder,
    required,
    touched,
    type = 'text',
  } = props;

  const hasErrors = !!errors[name] && !!touched[name];
  const isCheckbox = type === 'checkbox';

  return (
    <div className="flex flex-col gap-2 mb-4">
      {type !== 'checkbox' && (
        <div>
          <FormGroupLabel
            hasErrors={hasErrors}
            label={label}
            name={name}
            required={required}
          />
        </div>
      )}
      {!isCheckbox && (
        <Field
          className={`w-full rounded-md border px-5 py-3 text-sm focus:outline-none ${
            hasErrors
              ? 'border-error placeholder:text-error text-error'
              : 'border-gray/30 text-blue'
          }`}
          id={name}
          placeholder={placeholder || label}
          name={name}
          type={type}
        />
      )}
      {isCheckbox && (
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <Field
              aria-describedby={`${name}-description`}
              className="h-4 w-4 rounded focus:outline-none"
              id={name}
              name={name}
              type={type}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              id={`${name}-description`}
              htmlFor={name}
              className="font-thin"
            >
              {label}
            </label>
          </div>
        </div>
      )}
      {hasErrors && !hideErrors && <FormGroupError error={errors[name]} />}
      {children}
    </div>
  );
}

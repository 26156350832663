'use client';

import { ReactNode, useMemo } from 'react';

type CardProps = {
  animate?: boolean;
  border?: boolean;
  children: ReactNode;
  padding?: boolean;
  selected?: boolean;
};

export default function Card(props: CardProps) {
  const { animate, border, children, padding = true, selected } = props;

  const animateClassName = useMemo(() => {
    return animate && !selected
      ? 'transition ease-in-out hover:border-lightgreen hover:shadow-lightgreen/40 duration-200'
      : '';
  }, [animate, selected]);

  const selectedClassName = useMemo(() => {
    return selected ? 'border-lightgreen shadow-lightgreen/40' : 'border-white';
  }, [selected]);

  const borderClassName = useMemo(() => {
    return border ? 'border-2' : '';
  }, [border]);

  return (
    <div
      className={`bg-white shadow-md rounded-xl ${
        padding ? 'p-5 sm:px-9 sm:py-10' : ''
      } ${animateClassName} ${selectedClassName} ${borderClassName}`}
    >
      <div className="flex">
        <div className="flex flex-col w-full">{children}</div>
      </div>
    </div>
  );
}
